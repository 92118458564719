/**
 * @file mixin to help with sortable and draggable padlet posts
 */
import { captureException, captureMessage } from '@@/bits/error_tracker'
import { $ } from '@@/bits/jquery_ui_sortable'
import { computeAndSaveNewPostIndex } from '@@/surface/sorter' // TODO: Remove
import { mapActions } from 'vuex'
const CLIP_HEIGHT = 144

export default {
  data() {
    return {
      $_sortMixin_initialized: false,
      $_sortMixin_savePostHandler: null,
    }
  },
  updated() {
    if (this.$_sortMixin_initialized) {
      // if ((this.format === "stream" || this.format === "matrix") && this.canIWrite) {
      this.$_sortMixin_makeSortable()
    } else {
      this.$_sortMixin_destroySortable()
    }
  },
  beforeDestroy() {
    this.$_sortMixin_destroySortable()
  },
  methods: {
    ...mapActions(['changeDragging']),
    initializePostsSort(savePostHandler) {
      this.$_sortMixin_initialized = true
      this.$_sortMixin_savePostHandler = savePostHandler
      if (!savePostHandler) {
        captureMessage('Using SortMixin without declaring a handler function to save post.')
      }
    },
    destroyPostsSort() {
      this.$_sortMixin_initialized = false
      this.$_sortMixin_savePostHandler = null
    },
    $_sortMixin_savePost({ postCid, previousCid, nextCid }) {
      if (this.$_sortMixin_savePostHandler) {
        this.$_sortMixin_savePostHandler({ postCid, previousCid, nextCid })
      } else {
        captureException(new Error('No handler to save post for SortMixin.'))
      }
    },
    $_sortMixin_clipItems($el, e, item) {
      let itemHeight = item.height()
      let itemWidth = item.width()
      if (itemHeight > CLIP_HEIGHT) {
        let clipTop, clipBottom
        let distanceFromTarget = e.offsetY
        let distanceOfTargetFromTop =
          e.originalEvent.target.getBoundingClientRect().top - item[0].getBoundingClientRect().top
        let distanceFromTop = distanceFromTarget + distanceOfTargetFromTop
        if (distanceFromTop > CLIP_HEIGHT) {
          let distanceFromBottom = itemHeight - distanceFromTop
          if (distanceFromBottom < CLIP_HEIGHT) {
            clipTop = itemHeight - CLIP_HEIGHT
            clipBottom = itemHeight
          } else {
            clipTop = distanceFromTop - CLIP_HEIGHT / 2
            clipBottom = clipTop + CLIP_HEIGHT
          }
          item.data('clipTop', clipTop)
        } else {
          clipTop = 0
          clipBottom = CLIP_HEIGHT
        }

        // we need to adjust the clipping values a bit to give space for the post's shadow
        item.css('clip', `rect(${clipTop - 20}px, ${itemWidth + 30}px, ${clipBottom + 40}px, -20px)`)
      }
      return $el.addClass('ui-sortable-clipped')
    },

    $_sortMixin_unclipItems($el, e, item) {
      let ref, ref1
      let container = document.getElementById('wall-container')
      let oldOffsetTop = item[0].offsetTop
      $el.removeClass('ui-sortable-clipped')
      let clipTop = (ref = item.data('clipTop')) != null ? ref : 0
      let oldScrollTop = (ref1 = item.data('scrollTop')) != null ? ref1 : 0
      let newOffsetTop = item[0].offsetTop
      let offsetDifference = newOffsetTop - oldOffsetTop
      container.scrollTop = oldScrollTop + offsetDifference + clipTop
      item.removeData('clipTop')
      item.removeData('scrollTop')
    },

    $_sortMixin_saveScrollPosition($el, e, item) {
      let container = document.getElementById('wall-container')
      item.data('scrollTop', container.scrollTop)
    },

    $_sortMixin_markItemAsTransiting(item) {
      item.addClass('noclick in-transit')
    },

    $_sortMixin_unmarkItemAsTransiting(item) {
      item.removeClass('noclick in-transit')
    },
    $_sortMixin_makeSortable() {
      let $el = $(this.$el)
      if (!$el.sortable('instance')) {
        $el.sortable({
          cursor: 'move',
          items: '> .wish-editable:not(#wish-new)',
          scrollSensitivity: 48,
          cancel: '[contenteditable], .ui-resizable-handle, object, embed, input, button, textarea, select, option',
          start: (e, ui) => {
            $el.addClass('ui-sortable-sorting')
            if (this.$_sortMixin_initialized) {
              this.$_sortMixin_clipItems($el, e, ui.item)
              $el.sortable('refreshPositions')
            }
            this.$_sortMixin_markItemAsTransiting(ui.item)
            this.changeDragging({ isAPostDragging: true })
          },
          beforeStop: (e, ui) => {
            this.$_sortMixin_saveScrollPosition($el, e, ui.item)
          },
          stop: (e, ui) => {
            $el.removeClass('ui-sortable-sorting')
            if (this.$_sortMixin_initialized) {
              this.$_sortMixin_unclipItems($el, e, ui.item)
            }
            this.$_sortMixin_unmarkItemAsTransiting(ui.item)
            this.changeDragging({ isAPostDragging: false })
          },
          update: (e, ui) => {
            computeAndSaveNewPostIndex(ui.item)
          },
        })
      }
    },
    $_sortMixin_destroySortable: function () {
      let $el = $(this.$el).off('.sort').sortable('instance')
      if (!!$el) {
        $el.destroy()
      }
    },
  },
}
