/**
 * @file Composable to handle connections in canvas
 */

import { getJsPlumb, getJsPlumbBezierConnector, initiateJsPlumb } from '@@/bits/jsplumb'
import { vSet } from '@@/bits/vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostConnectionStore } from '@@/pinia/surface_post_connection'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfacePostsDragAndDropStore } from '@@/pinia/surface_posts_drag_and_drop'
import type { Cid, Id, Post, PostConnection } from '@@/types'
import type { BrowserJsPlumbInstance } from '@jsplumb/browser-ui'
import type { OverlaySpec } from '@jsplumb/common'
import type { BezierConnector } from '@jsplumb/connector-bezier'
import type { Connection as JsPlumbConnection } from '@jsplumb/core'
import { difference } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { computed, nextTick, ref, watch, type Ref } from 'vue'

interface TrackedConnection {
  postConnection: PostConnection
  jsplumbConnection: JsPlumbConnection<HTMLElement>
}

function getDisconnectOverlayId(connection: PostConnection): string {
  return `disconnect-${connection.from_wish_id}-${connection.to_wish_id}`
}

function getConnectionLabelId(fromId: string, toId: string): string {
  return `label-${fromId}-${toId}`
}

export const useSurfaceCanvasConnection = (el: Ref<HTMLElement>): void => {
  let jsPlumb: BrowserJsPlumbInstance
  let BezierConnector: BezierConnector

  const surfaceStore = useSurfaceStore()
  const { isCanvas, user } = storeToRefs(surfaceStore)

  const surfacePermissionsStore = useSurfacePermissionsStore()
  const { canIModerate, canIWrite } = storeToRefs(surfacePermissionsStore)

  const surfacePostsStore = useSurfacePostsStore()
  const { currentSortedPosts } = storeToRefs(surfacePostsStore)
  const { movePost } = surfacePostsStore

  const surfacePostConnection = useSurfacePostConnectionStore()
  const { currentConnections, postToDisconnectFromId } = storeToRefs(surfacePostConnection)
  const { completeDisconnection } = surfacePostConnection

  const surfaceContainerSizeStore = useSurfaceContainerSizeStore()
  const { isContainerSmallerThanTabletLandscape } = storeToRefs(surfaceContainerSizeStore)

  const surfacePostsDragAndDrop = useSurfacePostsDragAndDropStore()
  const { isAPostDragging } = storeToRefs(surfacePostsDragAndDrop)

  const existingConnections = ref<Record<Id, TrackedConnection | undefined>>({})
  const connectionsWithDisconnectOverlays = ref<TrackedConnection[]>([])

  const existingConnectionsArray = computed<TrackedConnection[]>(() => {
    return Object.values(existingConnections.value).filter((c) => c != null) as TrackedConnection[]
  })

  const isPostEditableByCurrentUser = (post: Post): boolean => {
    const wasWrittenByCurrentUser = user.value.id != null && post.author_id === user.value.id
    return canIModerate.value || (wasWrittenByCurrentUser && canIWrite.value)
  }

  const registerPostForDragging = (postElement: HTMLElement): void => {
    jsPlumb?.manage(postElement)
  }

  const registerAllPostsForDragging = (): void => {
    jsPlumb?.batch(() => {
      currentSortedPosts.value.forEach((p: Post) => {
        if (p.id == null) return
        const postElement = document.querySelector(`#wish-${p.id}`)
        if (postElement == null) return
        registerPostForDragging(postElement as HTMLElement)
      })
    })
  }

  const drawAllConnections = (): void => {
    jsPlumb?.batch(() => {
      currentConnections.value.forEach((connection: PostConnection) => {
        addConnection(connection)
      })
    })
  }

  const addConnection = (postConnection: PostConnection): void => {
    if (existingConnections.value[postConnection.id] != null) return // skip if already exists
    const jsplumbConnection = drawConnection(
      `${postConnection.from_wish_id}`,
      `${postConnection.to_wish_id}`,
      postConnection.label,
    )
    if (jsplumbConnection == null) return
    vSet(existingConnections.value, postConnection.id, { postConnection, jsplumbConnection })
  }

  const removeConnection = (postConnection: PostConnection): void => {
    const jsplumbConnection = existingConnections.value[postConnection.id]?.jsplumbConnection
    if (jsplumbConnection == null) return
    if (jsPlumb == null) return
    if (jsPlumb.deleteConnection(jsplumbConnection)) {
      vSet(existingConnections.value, postConnection.id, undefined) // remove from existing connections
    }
  }

  const addDisconnectOverlay = (connection: TrackedConnection): void => {
    const { postConnection, jsplumbConnection } = connection
    const disconnectOverlay = (_component: HTMLElement): HTMLDivElement => {
      const div = document.createElement('div')
      div.style.zIndex = '1'
      div.innerHTML = `<button class="floating-action-button-connector wish-disconnect-button" data-testid="postDisconnectButton"><i class="immaterial-icons">close</i></button>`
      const button = div.querySelector('button')

      button?.addEventListener('click', () => {
        completeDisconnection({ connectionId: postConnection.id })
      })
      return div
    }
    jsPlumb?.addOverlay(jsplumbConnection, {
      type: 'Custom',
      options: {
        create: disconnectOverlay,
        location: 0.3,
        id: getDisconnectOverlayId(connection.postConnection),
      },
    })
  }

  const drawConnection = (fromId: string, toId: string, label: string): JsPlumbConnection<HTMLElement> | undefined => {
    if (jsPlumb == null || BezierConnector == null) return
    let overlays: OverlaySpec[] = []
    const fromElement = document.getElementById('wish-' + fromId)
    const toElement = document.getElementById('wish-' + toId)

    if (fromElement == null || toElement == null) return undefined
    overlays = [
      {
        type: 'Arrow',
        options: {
          location: 1,
          width: 12,
          length: 12,
        },
      },
    ]
    if (label != null && label.length > 0) {
      overlays.push({
        type: 'Label',
        options: {
          label,
          id: getConnectionLabelId(fromId, toId),
          cssClass: 'wish-connection-label',
        },
      })
    }
    return jsPlumb.connect({
      source: fromElement,
      target: toElement,
      endpoint: 'Blank',
      connector: {
        type: BezierConnector?.type ?? 'Straight',
        options: {
          curviness: 50,
        },
      },
      anchor: 'AutoDefault',
      overlays,
      cssClass: `wish-connection-${fromId}-${toId}`,
    })
  }

  watch(
    currentSortedPosts,
    (currentPosts, prevPosts): void => {
      const newPosts = difference(currentPosts, prevPosts as Post[])
      newPosts.forEach((p: Post) => {
        if (p.id == null) return
        const postElement = document.querySelector(`#wish-${p.id}`)
        if (postElement == null) return
        if (isPostEditableByCurrentUser(p)) {
          registerPostForDragging(postElement as HTMLElement)
        } else {
          postElement.classList.add('is-not-editable')
        }
      })
    },
    { immediate: true },
  )

  watch(currentConnections, (newVal, oldVal): void => {
    jsPlumb?.batch(() => {
      const removedConnections: PostConnection[] = difference(oldVal, newVal) ?? []
      removedConnections.forEach(removeConnection)
    })
    nextTick(() => {
      // wait for DOM to update with new post to connect to
      jsPlumb?.batch(() => {
        const newConnections: PostConnection[] = difference(newVal, oldVal) ?? []
        newConnections.forEach(addConnection)
      })
    })
  })

  watch(postToDisconnectFromId, (postToDisconnectFromId: Id | null): void => {
    if (postToDisconnectFromId == null) {
      connectionsWithDisconnectOverlays.value.forEach((connection) => {
        jsPlumb?.removeOverlay(connection.jsplumbConnection as any, getDisconnectOverlayId(connection.postConnection))
      })
      connectionsWithDisconnectOverlays.value = []
      return
    }

    const connectionsToUpdate = existingConnectionsArray.value.filter((connection) => {
      return (
        connection?.postConnection.from_wish_id === postToDisconnectFromId ||
        connection?.postConnection.to_wish_id === postToDisconnectFromId
      )
    })
    connectionsWithDisconnectOverlays.value = connectionsWithDisconnectOverlays.value.concat(connectionsToUpdate)
    connectionsToUpdate.forEach(addDisconnectOverlay)
  })

  // When container size changes, posts are shifted a little.
  // We need to redraw the connections so they're still connected to the posts.
  watch(isContainerSmallerThanTabletLandscape, (newVal, oldVal): void => {
    // nextTick isn't enough for this. I've tried a few combinations and this works.
    nextTick(() => {
      setTimeout(() => {
        jsPlumb?.repaintEverything()
      }, 0)
    })
  })

  // Initialize JSPlumb
  const initializeJsPlumb = async (): Promise<void> => {
    if (!isCanvas.value) return
    const JsPlumb = await getJsPlumb()
    if (JsPlumb == null) return
    const SCROLL_OFFSET = 20
    const SCROLL_SPEED = 35
    const [jsPlumbInstance, BezierConnectorInstance] = await Promise.all([
      initiateJsPlumb({
        container: el.value,
        dragOptions: {
          containment: JsPlumb.ContainmentType.notNegative,
          trackScroll: true,
          filter: ':not(.is-not-editable)',
          start: (params) => {
            nextTick(() => {
              params.el.querySelector('.jtk-drag')?.classList.add('noclick', 'is-moving')
            })
            isAPostDragging.value = true
          },
          drag: (params) => {
            if (el.value.parentElement == null) return
            const currentScrollTop = el.value.parentElement?.scrollTop ?? 0
            const currentScrollLeft = el.value.parentElement?.scrollLeft ?? 0
            const currentHeight =
              el.value.parentElement.clientHeight -
              (el.value.parentElement.querySelector('#surface-header')?.clientHeight ?? 0)
            const currentWidth = el.value.parentElement.clientWidth ?? 0
            const postY1 = params.pos.y
            const postY2 = params.pos.y + params.size.h
            if (postY1 - SCROLL_OFFSET < currentScrollTop) {
              el.value.parentElement.scrollTop = currentScrollTop - SCROLL_SPEED
            }
            if (postY2 + SCROLL_OFFSET > currentScrollTop + currentHeight) {
              el.value.parentElement.scrollTop = currentScrollTop + SCROLL_SPEED
            }
            const postX1 = params.pos.x
            const postX2 = params.pos.x + params.size.w
            if (postX1 - SCROLL_OFFSET < currentScrollLeft) {
              el.value.parentElement.scrollLeft = currentScrollLeft - SCROLL_SPEED
            }
            if (postX2 + SCROLL_OFFSET > currentScrollLeft + currentWidth) {
              el.value.parentElement.scrollLeft = currentScrollLeft + SCROLL_SPEED
            }
          },
          stop: (params) => {
            const el = params.el
            setTimeout(() => {
              el?.classList.remove('noclick', 'is-moving')
            }, 100)
            movePost({
              postCid: params.el.dataset.postCid as Cid,
              position: { left: params.pos.x, top: params.pos.y },
            })
            isAPostDragging.value = false
          },
        },
      }),
      getJsPlumbBezierConnector(),
    ])
    if (jsPlumbInstance == null || BezierConnectorInstance == null) return
    jsPlumb = jsPlumbInstance
    registerAllPostsForDragging()
    BezierConnector = BezierConnectorInstance
    drawAllConnections()
  }
  void initializeJsPlumb()
}
