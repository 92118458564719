<template>
  <transition-group
    id="wish-list"
    tabindex="-1"
    ref="wishList"
    tag="div"
    :dir="dir"
    :class="['wish-list', isCanvas ? 'relative' : '', isAnimationPermitted ? 'allows-transition' : '']"
    :enter-class="isAnimationPermitted ? 'opacity-0' : ''"
    :enter-active-class="isAnimationPermitted ? 'transform-opacity ease-out' : 'ease-out'"
    :enter-to-class="isAnimationPermitted ? 'wish-enter' : ''"
    :leave-class="isMatrix ? 'opacity-100' : ''"
    :leave-active-class="isMatrix ? 'wish-leave transform-opacity ease-in absolute' : 'ease-in'"
    :leave-to-class="isMatrix ? 'opacity-0' : ''"
    :duration="isAnimationPermitted ? 500 : 0"
    @before-leave="
      ($el) => {
        !isMatrix ? setHeight($el) : undefined
      }
    "
    @leave="
      ($el) => {
        !isMatrix ? staggerWishLeave($el) : undefined
      }
    "
    @after-enter="endAnimationForFilterChanged"
    @after-leave="endAnimationForFilterChanged"
  >
    <surface-post
      v-for="(post, index) in currentPosts"
      ref="surfacePosts"
      :key="post.cid"
      :class="[!shouldDisplayPost(post.cid) && 'hidden', !canDragPinnedPostInCanvas(post) && 'no-drag-canvas']"
      :data-index="index"
      :data-cid="post.cid"
      :index="index"
      :post="post"
      :post-reaction-ids="reactionIdsByPostId[post.id]"
      :post-comment-ids="commentIdsByPostId[post.id]"
      :lazy-render="isAppUsing('lazyRenderPosts')"
      :min-post-height="minHeight"
      :min-post-width="256"
      @focus="$emit('focus-post', post)"
    />
    <!-- This should stay under the same parent as the posts for sizing to work properly because it relies on stuffs like calc(25% - 16px) -->
    <div :key="'sizer'" class="wish-sizer" />
  </transition-group>
</template>

<script>
import { isElementInViewport, isElementOnTopOfViewport } from '@@/bits/dom'
import { isAppUsing } from '@@/bits/flip'
import { $ } from '@@/bits/jquery'
import { isPostPinned } from '@@/bits/post_state'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { scrollToPost } from '@@/surface/scroll_to_new_post'
import SurfacePost from '@@/vuecomponents/surface_post.vue'
import SortMixin from '@@/vuemixins/sort_mixin'
import { difference } from 'lodash-es'
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { SurfacePost },
  mixins: [SortMixin],
  props: {
    reactionIdsByPostId: { type: Object, default: () => ({}) },
    commentIdsByPostId: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      delayingUpdatePosts: false,
      currentPosts: [],
      difference: [],
      isAnimationPermitted: false,
    }
  },
  computed: {
    ...mapGetters('post', ['postEntitiesByCid', 'newestPostCid']),
    ...mapGetters({
      isMatrix: 'isMatrix',
      isCanvas: 'isCanvas',
      dir: 'dir',
      format: 'format',
      canIWrite: 'canIWrite',
      newPostLocation: 'newPostLocation',
    }),
    ...mapPiniaState(useSurfacePostsStore, ['allSortedPosts']),
    ...mapPiniaState(useSurfaceStore, ['isFrozen']),
    minHeight() {
      // Canvas does not have comments, so the minimum height should be 42px
      return 42
    },
    minWidth() {
      // Canvas has a minimum width of 256px
      return 256
    },
  },
  watch: {
    allSortedPosts(newVal, oldVal) {
      // Must do this so that vue won't rerender and crash shapeshift
      // TODO: remove jquery and eventually shapeshift. (see GridMixin)
      const $draggingElement = $(this.$el).find('.ss-dragged-child')
      if (!$draggingElement[0]) {
        this.currentPosts = newVal
        this.previousPosts = oldVal
      }
      const postDifference = difference(oldVal, newVal)
      this.difference = postDifference.filter((p) => {
        const postElement = document.querySelector(`#wish-${p.id}`)
        if (!postElement) return false
        return isElementInViewport(postElement)
      })
    },
    newestPostCid(postCid) {
      if (this.format === 'free') {
        scrollToPost(document.getElementById('wall-container'), this.postEntitiesByCid[postCid])
      }
    },
    // TODO: If we want to add filter animation back,
    // add a watcher on the new filter state to trigger
    // this method.
    // filter() {
    //   // Start animation when filter changed
    //   this.isAnimationPermitted = true
    // },
    // xFilterPopover(val) {
    //   if (!this.isMatrix) return
    //   if (val) {
    //     this.$refs.surfacePosts.forEach((vue) => {
    //       this.setPosition(vue.$el)
    //     })
    //   }
    // },
  },
  mounted() {
    this.currentPosts = this.allSortedPosts
    if ((this.format === 'stream' || this.format === 'matrix') && this.canIWrite) {
      this.initializePostsSort(({ postCid, previousCid, nextCid }) => {
        this.sortedPost({ postCid, sectionId: null, previousCid, nextCid })
      })
    }
  },
  methods: {
    ...mapActions('post', ['sortedPost']),
    ...mapPiniaActions(useSurfacePostsStore, ['shouldDisplayPost']),
    canDragPinnedPostInCanvas(post) {
      return this.isCanvas && !isPostPinned(post) && !this.isFrozen
    },
    endAnimationForFilterChanged() {
      setTimeout(() => {
        this.isAnimationPermitted = false
      }, 100) // the animation still need a little bit of time ~50-100ms for the transition to be totally over
    },
    onGridFinishDragging() {
      if (this.delayingUpdatePosts) {
        this.delayingUpdatePosts = false
        this.currentPosts = this.allSortedPosts
      }
    },
    setHeight(el) {
      el.style.height = `${el.getBoundingClientRect().height}px`
    },
    setPosition(el) {
      if (!isElementInViewport(el)) {
        el.style.top = `${window.innerHeight || document.documentElement.clientHeight + 1000}px`
        el.style.left = `${window.innerWidth || document.documentElement.clientWidth + 1000}px`
        return
      }
      el.style.top = `${
        el.getBoundingClientRect().top -
        el.parentNode.parentNode.getBoundingClientRect().top -
        parseInt(getComputedStyle(el).marginTop, 10)
      }px`
      el.style.left = `${
        el.getBoundingClientRect().left -
        el.parentNode.parentNode.getBoundingClientRect().left -
        parseInt(getComputedStyle(el).marginLeft, 10)
      }px`
    },
    staggerWishLeave(el) {
      if (!this.isAnimationPermitted) return
      const positionInHidingOrder = this.difference.findIndex((p) => p.cid === el.dataset.cid)
      const delay = this.isCanvas ? 0 : (300 / (this.difference.length - 1)) * Math.max(positionInHidingOrder, 0)
      el.style.transition = isElementOnTopOfViewport(el) // if post is on top, we hide them with animation so that the scrollbar does not jump
        ? 'all 500ms cubic-bezier(0,0,0.2,1)'
        : positionInHidingOrder < 0
        ? 'none'
        : this.difference.length === 1 || this.isCanvas
        ? 'all 500ms cubic-bezier(0,0,0.2,1)'
        : 'all 200ms cubic-bezier(0,0,0.2,1)'
      el.style.opacity = `1`
      setTimeout(() => {
        el.style.height = `0px`
        el.style.margin = `0px`
        el.style.opacity = `0`
      }, delay)
    },
    isAppUsing,
  },
}
</script>

<style lang="scss">
.surface-post.wish-enter {
  @apply animate-fade-in-slow;
}
.surface-post.wish-leave {
  @apply animate-fade-out-slow;
}
.allows-transition {
  .surface-post:not(.wish-enter) {
    // :not(.wish-enter) prevents new posts from having 500ms transition when appearing
    @apply transition-all;
    @apply duration-500;
  }
}
</style>
