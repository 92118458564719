<script setup lang="ts">
import device from '@@/bits/device'
import { __, p__ } from '@@/bits/intl'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePostConnectionStore } from '@@/pinia/surface_post_connection'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import type { Id, Post, PostConnection } from '@@/types'
import ScreenReaderSpeechNotifications from '@@/vuecomponents/ScreenReaderSpeechNotifications.vue'
import SurfaceHeader from '@@/vuecomponents/SurfaceHeader.vue'
import SurfacePosts from '@@/vuecomponents/surface_posts.vue'
import { useSurfaceCanvasConnection } from '@@/vuecomposables/surface_canvas_connection'
import { useDoubleClickToPost } from '@@/vuecomposables/surface_double_click_to_post'
import { storeToRefs } from 'pinia'
import { onMounted, ref, type Ref } from 'vue'

withDefaults(
  defineProps<{
    reactionIdsByPostId: Record<Id, Id[]>
    commentIdsByPostId: Record<Id, Id[]>
  }>(),
  {
    reactionIdsByPostId: () => ({}),
    commentIdsByPostId: () => ({}),
  },
)

const emit = defineEmits<{
  (name: 'scroll', e: UIEvent): void
}>()

const { xHeader, backgroundLuminance, isReadOnly } = storeToRefs(useSurfaceStore())
const wallContainer = ref<HTMLDivElement | null>(null)
const isPageScrollHandlerTicking = ref(false)
const headerTop = ref(0)
const headerLeft = ref(0)

// Keep the header at the same position when the page is scrolled
const handlePageScroll = (e: UIEvent): void => {
  emit('scroll', e)
  if (wallContainer.value != null && !isPageScrollHandlerTicking.value) {
    requestAnimationFrame(() => {
      const wallContainerEl = wallContainer.value as HTMLDivElement
      headerTop.value = -wallContainerEl.scrollTop
      headerLeft.value = wallContainerEl.scrollLeft
      isPageScrollHandlerTicking.value = false
    })
    isPageScrollHandlerTicking.value = true
  }
}

if (!device.mobile) useDoubleClickToPost()

/**
 * ==========================================
 * Accessibility for Screen Readers
 * ==========================================
 */
const { getConnectionsFromPost, getConnectionsToPost } = useSurfacePostConnectionStore()
const { postEntitiesById, currentSortedPosts } = storeToRefs(useSurfacePostsStore())
const screenReaderMessage = ref('')

/**
 * Creates screen reader description for post connections. If the connection has a label, it will be included.
 *
 * @param {string} postConnections - The post connections to create the description for.
 * @param {keyof PostConnection} connectionQueryKey - the field to read from the post connection to retrieve the post
 * @returns {string} - The description for the post connections.
 */
const getDescriptionForPostConnections = (
  connections: PostConnection[],
  connectionQueryKey: keyof PostConnection,
): string => {
  const connectionDescriptions = connections.map((connection) => {
    const connectedPost = postEntitiesById.value[connection[connectionQueryKey]]
    const index = currentSortedPosts.value.findIndex((p) => p.cid === connectedPost.cid)
    if (index === -1) return ''

    const postIndex = index + 1
    const label = connection.label
    if (label) {
      return p__(
        'Describes the connection between two posts and its label',
        'Post %{postIndex} of %{numPosts} posts, with the label of: "%{label}"',
        {
          postIndex,
          numPosts: currentSortedPosts.value.length,
          label,
        },
      )
    }
    return p__(
      'Describes the connection between two posts without a label',
      'Post %{postIndex} of %{numPosts} posts, without a label',
      {
        postIndex,
        numPosts: currentSortedPosts.value.length,
      },
    )
  })
  return connectionDescriptions.join(', ')
}
const updateScreenReaderMessageOnPostFocus = (post: Post): void => {
  const index = currentSortedPosts.value.findIndex((p) => p.cid === post.cid)
  if (index === -1) return

  const postIndex = index + 1
  if (post.id) {
    const connectionsFromPost = getConnectionsFromPost(post.id)
    const connectionsToPost = getConnectionsToPost(post.id)
    const hasConnectionsFromPost = connectionsFromPost.length > 0
    const hasConnectionsToPost = connectionsToPost.length > 0
    const hasConnections = hasConnectionsFromPost || hasConnectionsToPost
    if (hasConnections) {
      const hasBothConnectionsFromAndToPost = hasConnectionsFromPost && hasConnectionsToPost
      let connectionDescription: string
      if (hasBothConnectionsFromAndToPost) {
        connectionDescription = p__(
          'Description for all post connections from and to this post',
          'This post is connected from %{connectionsFromPost} and connected to %{connectionsToPost}.',
          {
            connectionsFromPost: getDescriptionForPostConnections(connectionsFromPost, 'to_wish_id'),
            connectionsToPost: getDescriptionForPostConnections(connectionsToPost, 'from_wish_id'),
          },
        )
      } else if (hasConnectionsFromPost) {
        connectionDescription = p__(
          'Description for all post connections from this post',
          'This post is connected to %{connectionsFromPost}. No other posts are connected to this post.',
          {
            connectionsFromPost: getDescriptionForPostConnections(connectionsFromPost, 'to_wish_id'),
          },
        )
      } else {
        connectionDescription = p__(
          'Description for all post connections to this post',
          'This post is connected from %{connectionsToPost}. It does not connect to other posts.',
          {
            connectionsToPost: getDescriptionForPostConnections(connectionsToPost, 'from_wish_id'),
          },
        )
      }

      screenReaderMessage.value = p__(
        'This is a message announced to screenreaders to let them know of the current focused post they are at, the index of the post they are at out of the total number of posts on the padlet, and information about the posts that are connected to this post',
        '%{postIndex} of %{numPosts} posts. %{connectionDescription}',
        {
          postIndex,
          numPosts: currentSortedPosts.value.length,
          connectionDescription,
        },
      )
      return
    }
  }

  screenReaderMessage.value = p__('Post index out of the total number of posts', '%{postIndex} of %{numPosts}', {
    postIndex,
    numPosts: currentSortedPosts.value.length,
  })
}

onMounted(() => {
  useSurfaceCanvasConnection(wallContainer as Ref<HTMLElement>)
})
</script>

<template>
  <section
    id="wall-container"
    ref="wallContainer"
    :class="[
      {
        relative: true,
        'scrollbar-photo': backgroundLuminance === 'light',
        'scrollbar-photo-dark': backgroundLuminance === 'dark',
      },
      !isReadOnly && 'overflow-auto',
    ]"
    :style="{
      direction: 'ltr',
    }"
    :aria-label="__('Canvas')"
    @scroll="handlePageScroll"
  >
    <ScreenReaderSpeechNotifications :message="screenReaderMessage" />
    <SurfaceHeader
      v-if="xHeader"
      class="fixed end-0"
      :style="{
        top: `${headerTop}px`,
        left: `${headerLeft}px`,
      }"
    />
    <SurfacePosts
      :reaction-ids-by-post-id="reactionIdsByPostId"
      :comment-ids-by-post-id="commentIdsByPostId"
      @focus-post="updateScreenReaderMessageOnPostFocus"
    />
    <div class="gutter-sizer" />
  </section>
</template>

<style>
.wish-connection-label {
  /* Label should be above the arrow */
  z-index: 1;
}
</style>
