// @file Helper methods to scroll to post
// TODO: Create and use non-jquery version in bits/dom_helpers instead.
import { $ } from '@@/bits/jquery'
import type { PostAttributes } from '@@/types'
/* 
Helper function to check if element is in view.
*/

function isShelfPostInViewport(element): boolean {
  const rect = element.getBoundingClientRect()
  return rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
}

export const scrollNewPostIntoView = (el: HTMLElement): void => {
  const element = el ?? (document.getElementById('wish-new') as HTMLElement)
  element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' })
}

export const scrollToPost = (containerElement: HTMLElement, postAttributes: PostAttributes): void => {
  if (!postAttributes.left || !postAttributes.top) return
  const wallContainerWidth: number = containerElement.offsetWidth
  const scrollLeft: number = Math.max(postAttributes.left - (wallContainerWidth - (postAttributes.width || 0)) / 2, 0)
  const scrollTop: number = Math.max(postAttributes.top - 200, 0)
  $(containerElement).animate({ scrollLeft, scrollTop })
}

export const scrollShelfDownToPost = (containerElement: HTMLElement): void => {
  if (!containerElement?.children) {
    return
  }

  const children = containerElement.children
  const secondToLastChild = children[children.length - 2]
  const lastChild = children[children.length - 1]

  if (!secondToLastChild || !lastChild) {
    return
  }
  if (isShelfPostInViewport(secondToLastChild)) {
    lastChild.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }
}
